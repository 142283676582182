// ripped from https://bitbucket.org/spinsllc/edp-sdn-playground/src/master/src/auth/*
import React from "react";

import JwtDecode from 'jwt-decode'


function isTokenExpired(jwtDecode) {
  return jwtDecode.exp < Math.floor(Date.now() / 1000)
}

export const getOptionalAuthorizationRedirect = (accessToken = null) => {
  if (accessToken === null) {
    return 'login'
  }
  const jwtDecode = JwtDecode(accessToken);
  return !isTokenExpired(jwtDecode) ? undefined : 'logout'
};



export const getLoginRedirectUrl = () => {
  const clientId = process.env.REACT_APP_AUTH_CLIENT_ID;
  const audience = process.env.REACT_APP_AUTH_AUDIENCE;
  const auth0tenant = process.env.REACT_APP_AUTH_URL;
  return `${auth0tenant}/authorize?response_type=token&client_id=${clientId}&redirect_uri=${window.location.origin}/loginCallback&audience=${audience}`
};

export const Logout = () => {
    window.localStorage.removeItem('access_token');
    window.location.assign(window.location.origin + '/');
    return <></>;
};

export const Login = () => {
    window.location.assign(getLoginRedirectUrl());
    return <></>;
};

export const getAccessToken = (inputHash) => {
    const regexInput = inputHash.match(/#(?:access_token)=([\S\s]*?)&/);
    return regexInput !== null ? regexInput[1] : undefined
};

export const LoginCallback = (props) => {
    const accessToken = getAccessToken(props.location.hash) || '';
    window.localStorage.setItem('access_token', accessToken);
    window.location.assign(window.location.origin + '/');
    return <></>;
};

export const RouteWithAuthorization = (props) => {
    function getAccessToken() {
        return window.localStorage.getItem('access_token');
    }

    function isTokenExpired(accessToken) {
        return JwtDecode(accessToken).exp < Math.floor(Date.now() / 1000)
    }

    const accessToken = getAccessToken();

    if (accessToken === null) {
        return <Login/>;
    }

    if (isTokenExpired(accessToken)) {
        return <Logout/>;
    }
    return props.children;
};
