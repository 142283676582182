// @flow

import React from "react";
import FullExplorer from "./components/FullExplorer";
import "./App.css";

import { Route, Switch, Redirect } from "react-router-dom";
import {
  Login,
  LoginCallback,
  Logout,
  RouteWithAuthorization,
} from "./components/Auth";

const GRAPHQL_URL = process.env.GRAPHQL_URL || "https://sdn.spins.com/api/";
console.log("GRAPHQL_URL:", GRAPHQL_URL);

function fetcher(params: Object): Object {
  if (document) {
    document.title = `GraphiQL | ${process.env.REACT_APP_API_BASE_URL}`;
  }
  return fetch(process.env.REACT_APP_API_BASE_URL, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "x-api-key": process.env.REACT_APP_API_KEY,
    },
    body: JSON.stringify(params),
  })
    .then(function (response) {
      return response.text();
    })
    .then(function (responseBody) {
      try {
        return JSON.parse(responseBody);
      } catch (e) {
        return responseBody;
      }
    });
}

const DEFAULT_QUERY = `## Shortcuts ##
# <shift-alt-click> to view key in Explorer panel
# <command-click> to view key in Docs panel
`;

function App() {
  return (
    <Switch>
      <Route path="/login" component={Login} />
      <Route path="/loginCallback" component={LoginCallback} />
      <Route path="/logout" component={Logout} />
      <Route path="/GraphiQL">
        <RouteWithAuthorization>
          <FullExplorer fetcher={fetcher} defaultQuery={DEFAULT_QUERY} />
        </RouteWithAuthorization>
      </Route>
      <Redirect to="/GraphiQL" />
    </Switch>
  );
}

export default App;
